import React, { useState, useEffect, useContext } from 'react';
import { getTrainerReportApi } from '../../../src/api/endpoints';
import '../../Styles/TrainingAdmin.css';
import back from '../../assets/Images/backarrow.png'

import Next from '../../assets/Images/nextarrow.png';
import { SearchContext } from '../../AllSearch/SearchContext';

const TrainerFeedback = () => {
  const [feedback, setFeedback] = useState([]);
  const [currentTrainerIndex, setCurrentTrainerIndex] = useState(0); // Track the index of the currently displayed trainer
  const [error, setError] = useState(null);
  const { searchQuery } = useContext(SearchContext);
  const [search, setSearch] = useState('');

  useEffect(() => {
    // Fetch trainer data when the component mounts
    fetchFeedback();
  }, [searchQuery, search]);

  const fetchFeedback = async () => {
    try {
      const response = await getTrainerReportApi();
      // Filter response based on filter criteria
      const filteredFeedback = response.filter(item =>
        (searchQuery ? (
          (item.college_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.department_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.year?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.topic?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.sub_topic?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.trainer_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.dtm_of_training?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.status?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.student_feedback?.toLowerCase() || '').includes(searchQuery.toLowerCase()) || 
          (item.infrastructure_feedback?.toLowerCase() || '').includes(searchQuery.toLowerCase()) || 
          (String(item.activities_done)?.toLowerCase() || '').includes(searchQuery.toLowerCase()) || // Convert to string
          (String(item.no_of_question_solved)?.toLowerCase() || '').includes(searchQuery.toLowerCase()) || // Convert to string
          (item.remarks?.toLowerCase() || '').includes(searchQuery.toLowerCase())
        ) : true) &&
        (search ? (
          (item.college_name?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.department_name?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.year?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.topic?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.sub_topic?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.trainer_name?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.dtm_of_training?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.status?.toLowerCase() || '').includes(search.toLowerCase()) ||
          (item.student_feedback?.toLowerCase() || '').includes(search.toLowerCase()) || 
          (item.infrastructure_feedback?.toLowerCase() || '').includes(search.toLowerCase()) || 
          (String(item.activities_done)?.toLowerCase() || '').includes(search.toLowerCase()) || // Convert to string
          (String(item.no_of_question_solved)?.toLowerCase() || '').includes(search.toLowerCase()) || // Convert to string
          (item.remarks?.toLowerCase() || '').includes(search.toLowerCase())
        ) : true) 
      );
      setFeedback(filteredFeedback); // Set the filtered data to the state
    } catch (error) {
      setError('Failed to fetch trainer data');
      console.error(error);
    }
  };

  // Handle Next and Back navigation
  const nextTrainer = () => {
    if (currentTrainerIndex < feedback.length - 1) {
      setCurrentTrainerIndex(currentTrainerIndex + 1);
    }
  };

  const prevTrainer = () => {
    if (currentTrainerIndex > 0) {
      setCurrentTrainerIndex(currentTrainerIndex - 1);
    }
  };

  // Get the current trainer based on the current index
  const currentTrainer = feedback[currentTrainerIndex];

  return (
    <div className="form-ques-stufeed">
      <h5>Trainers Report</h5>

      <input
        className="search-box1"
        type="text"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ marginBottom: '10px' }}
      />
      <div className="form-ques">
        {feedback.length > 0 && currentTrainer ? (
          <div className="trainer-card">
            <p><strong>College Name:</strong> {currentTrainer.college_name}</p>
            <p><strong>Department:</strong> {currentTrainer.department_name}</p>
            <p><strong>Year:</strong> {currentTrainer.year}</p>

            <p><strong>Topic:</strong> {currentTrainer.topic}</p>
            <p><strong>Sub Topic:</strong> {currentTrainer.sub_topic}</p>
            <p><strong>Trainer name:</strong> {currentTrainer.trainer_name}</p>
            <p><strong>Session Date:</strong> {currentTrainer.dtm_of_training}</p>
            <p><strong>Status</strong>{currentTrainer.status}</p>
            <p><strong>Comments</strong>{currentTrainer.comments}</p>

            <p><strong>Students Feedback:</strong> {currentTrainer.student_feedback}</p>
            <p><strong>Infrastructure Feedback:</strong> {currentTrainer.infrastructure_feedback}</p>
            <p><strong>Activities Done: </strong>{currentTrainer.activities_done}</p>
            <p><strong>No of Question Solved: </strong>{currentTrainer.no_of_question_solved}</p>

            <p><strong>Remarks:</strong> {currentTrainer.remarks}</p>


            {/* Pagination controls aligned to the right */}
            <div className="pagi-container">
              <button
              className='stu-fee'
                onClick={prevTrainer}
                disabled={currentTrainerIndex === 0}
                style={{
                  backgroundColor: currentTrainerIndex === 0 ? 'f3de59' : '#F1A128', // Gray if disabled, otherwise orange
                  cursor: currentTrainerIndex === 0 ? 'not-allowed' : 'pointer' // Change cursor if disabled
                }}
              >
                <img src={back} className='nextarrow' alt="Back Arrow" />
              </button>

              <button
              className='stu-fee'
                onClick={nextTrainer}
                disabled={currentTrainerIndex === feedback.length - 1}
                style={{
                  backgroundColor: currentTrainerIndex === feedback.length - 1 ? 'f3de59' : '#F1A128', // Gray if disabled, otherwise orange
                  cursor: currentTrainerIndex === feedback.length - 1 ? 'not-allowed' : 'pointer' // Change cursor if disabled
                }}
              >
                <img src={Next} className='nextarrow' alt="Next Arrow" />
              </button>

              <p>
                Showing Feedback {currentTrainerIndex + 1} of {feedback.length}
              </p>
            </div>

          </div>
        ) : (
          <p>No feedback found</p>
        )}
      </div>
    </div>
  );
};

export default TrainerFeedback;
