import React, { useState, useEffect } from 'react';
import { getfiltered_StudentsAPI, getdepartmentApi, getcollegeApi } from '../../api/endpoints'; // Assuming your API call is in a separate file
import * as XLSX from 'xlsx';
import Select from 'react-select';
import CustomOption from '../Test/CustomOption';
import { Container, Card, Col, Row, Form, Button } from 'react-bootstrap';
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#39444e',
        color: '#fff', // Text color
        borderColor: state.isFocused ? '' : '#ffff', // Border color on focus
        boxShadow: 'none', // Remove box shadow
        '&:hover': {
            borderColor: state.isFocused ? '#ffff' : '#ffff' // Border color on hover
        },
        '&.css-1a1jibm-control': {
            // Additional styles for the specific class
        }
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#ffff' // Text color for selected value
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#39444e' : state.isFocused ? '#39444e' : '#39444e',
        color: '#ffff', // Text color
        '&:hover': {
            backgroundColor: '#39444e', // Background color on hover
            color: '#ffff' // Text color on hover
        }
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#39444e'
    })
};
const FilterCandidatesDownload = ({ collegeName }) => {
    const [collegeId, setCollegeId] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [collegeOptions, setCollegeOptions] = useState([]); // Add state for colleges
    const [year, setYear] = useState('');
    const [marks10th, setMarks10th] = useState('');
    const [marks12th, setMarks12th] = useState('');
    const [cgpaScore, setCgpaScore] = useState('');
    const [standingArrears, setStandingArrears] = useState('');
    const [historyOfArrears, setHistoryOfArrears] = useState('');
    const [noOfOffers, setNoOfOffers] = useState('');
    const [gender, setGender] = useState('');
    const [skillOptions, setSkillOptions] = useState([]);
    const [departmentIds, setDepartmentIds] = useState([]);

    useEffect(() => {
        if (collegeName) {
            getcollegeApi()
                .then(data => {
                    setCollegeOptions(data); // Store all college data
                    const college = data.find(item => item.college === collegeName);
                    if (college) {
                        setCollegeId(Number(college.id));
                    }
                })
                .catch(error => console.error('Error fetching College:', error));
        }
      
        getdepartmentApi()
            .then(data => {
                setDepartmentOptions(data.map(item => ({ value: item.id, label: item.department })));
            })
            .catch(error => console.error('Error fetching Departments:', error));
            
    }, [collegeName]);
  
    const handleDepartmentChange = (selectedOption) => {
        setDepartmentId(selectedOption ? selectedOption.value : '');
    };
    const handleDownloadold = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
    
        try {
            console.log('Starting download process...');
            console.log('Current filter values:');
            console.log('College ID:', collegeId);
            console.log('Department ID:', departmentId);
            console.log('Year:', year);
            console.log('Marks (10th):', marks10th);
            console.log('Marks (12th):', marks12th);
            console.log('CGPA:', cgpaScore);
            console.log('Standing Arrears:', standingArrears);
            console.log('History of Arrears:', historyOfArrears);
            console.log('Number of Offers:', noOfOffers);
            console.log('Gender:', gender);
    
            const data = await getfiltered_StudentsAPI(
                Number(collegeId),
                Number(departmentId),
                
                year,
                marks10th,
                marks12th,
                cgpaScore,
                standingArrears,
                historyOfArrears,
                noOfOffers,
                gender
            );
    
            // Map IDs to names
            const collegeNameMap = collegeOptions.reduce((acc, item) => {
                acc[item.id] = item.college;
                return acc;
            }, {});
    
            const departmentNameMap = departmentOptions.reduce((acc, item) => {
                acc[item.value] = item.label;
                return acc;
            }, {});
    
            // Transform the data
            const updatedData = data.map(item => ({
                ...item,
                college_name: collegeNameMap[item.college_id] || 'Unknown',
                department_name: departmentNameMap[item.department_id] || 'Unknown',
                
            }));
    
              const finalData = updatedData.map(({ college_id, department_id,id,skill_id, ...rest }) => rest);

           console.log('Filtered student data received from API:', finalData);
   
           const worksheet = XLSX.utils.json_to_sheet(finalData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Filtered Candidates');
    
            XLSX.writeFile(workbook, 'filtered_candidates.xlsx');
            console.log('Download completed.');
        } catch (error) {
            console.error('Error fetching and downloading data:', error);
        }
    };
    const handleDownload = async (event) => {
        event.preventDefault();
        
        try {
            console.log('Starting download process...');
            console.log('Current filter values:');
            console.log('College ID:', collegeId);
            console.log('Department IDs:', departmentIds); // Updated for multiple departments
            console.log('Year:', year);
            console.log('Marks (10th):', marks10th);
            console.log('Marks (12th):', marks12th);
            console.log('CGPA:', cgpaScore);
            console.log('Standing Arrears:', standingArrears);
            console.log('History of Arrears:', historyOfArrears);
            console.log('Number of Offers:', noOfOffers);
            console.log('Gender:', gender);
            
            const data = await getfiltered_StudentsAPI(
                Number(collegeId),
                departmentIds, // Pass the array of department IDs
                year,
                marks10th,
                marks12th,
                cgpaScore,
                standingArrears,
                historyOfArrears,
                noOfOffers,
                gender
            );
    
            // Mapping and transforming data as before
            const collegeNameMap = collegeOptions.reduce((acc, item) => {
                acc[item.id] = item.college;
                return acc;
            }, {});
    
            const departmentNameMap = departmentOptions.reduce((acc, item) => {
                acc[item.value] = item.label;
                return acc;
            }, {});
    
            const updatedData = data.map(item => ({
                ...item,
                college_name: collegeNameMap[item.college_id] || 'Unknown',
                department_name: departmentNameMap[item.department_id] || 'Unknown',
            }));
    
            const finalData = updatedData.map(({ college_id, department_id, id, skill_id, ...rest }) => rest);
    
            console.log('Filtered student data received from API:', finalData);
            
            const worksheet = XLSX.utils.json_to_sheet(finalData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Filtered Candidates');
    
            XLSX.writeFile(workbook, 'filtered_candidates.xlsx');
            console.log('Download completed.');
        } catch (error) {
            console.error('Error fetching and downloading data:', error);
        }
    };
    
  
    return (
        <div className='form-ques'>
            <h5>Download Eligible Students</h5>
            <form className='form-ques'>
                <Row md={12}>
                    <Col>
                       {/*} <div controlId='department'>
                            <label className='label6-ques'>Department</label><p></p>
                            <Select
                                options={departmentOptions}
                                onChange={handleDepartmentChange}
                                placeholder="Select department"
                                styles={customStyles} // Define custom styles if needed
                            />
                        </div>*/}
                        <Col>
    <label className='label6-ques'>Department</label><p></p>
    <Select
        options={departmentOptions}
        isMulti
        onChange={(selectedOptions) => {
            const selectedIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
            setDepartmentIds(selectedIds);
        }}
        components={{ Option: CustomOption }}
        closeMenuOnSelect={false}
        placeholder="Select departments"
        styles={customStyles} // Define custom styles if needed
    />
</Col>

                    </Col>
                    <Col>
    <label className='label5-ques'>Year</label><p></p>
    <select
        className='input-ques'
        value={year}
        onChange={(e) => {
            console.log('Year selected:', e.target.value);
            setYear(e.target.value);
        }}
    >
        <option value="">Select Year</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
    </select>
</Col>

                    <Col>
                        <label className='label5-ques'>Gender</label><p></p>
                        <select
                            className='input-ques'
                            value={gender}
                            onChange={(e) => {
                                console.log('Gender input changed:', e.target.value);
                                setGender(e.target.value);
                            }}
                        >
                            <option value="">Select Gender</option>
                            <option value="Both">Both</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </Col>
                </Row><p></p>
                <Row md={12}>
                    <Col>
                        <label className='label5-ques'>Marks (10th)</label><p></p>
                        <input 
                            type="number" 
                            className='input-ques'
                            max='100'
                            min='0' 
                            value={marks10th} 
                            onChange={(e) => {
                                console.log('Marks (10th) input changed:', e.target.value);
                                setMarks10th(e.target.value);
                            }} 
                        />
                    </Col>
                    <Col>
                        <label className='label5-ques'>Marks (12th)</label><p></p>
                        <input 
                            type="number" 
                            className='input-ques' 
                             max='100'
                            min='0'
                            value={marks12th} 
                            onChange={(e) => {
                                console.log('Marks (12th) input changed:', e.target.value);
                                setMarks12th(e.target.value);
                            }} 
                        />
                    </Col>
                    <Col>
                        <label className='label5-ques'>CGPA</label><p></p>
                        <input 
                            type="number" 
                            step="0.01" 
                            max='10'
                            min='0'
                            className='input-ques' 
                            value={cgpaScore} 
                            onChange={(e) => {
                                console.log('CGPA input changed:', e.target.value);
                                setCgpaScore(e.target.value);
                            }} 
                        />
                    </Col>
                </Row><p></p>
                <Row>
                    <Col>
                        <label className='label5-ques'>Standing Arrears</label><p></p>
                        <input 
                            type="number" 
                            className='input-ques' 
                            value={standingArrears} 
                            onChange={(e) => {
                                console.log('Standing Arrears input changed:', e.target.value);
                                setStandingArrears(e.target.value);
                            }} 
                        />
                    </Col>
                    <Col>
                        <label className='label5-ques'>History of Arrears</label><p></p>
                        <input 
                            type="number" 
                            className='input-ques' 
                            value={historyOfArrears} 
                            onChange={(e) => {
                                console.log('History of Arrears input changed:', e.target.value);
                                setHistoryOfArrears(e.target.value);
                            }} 
                        />
                    </Col>
                    <Col>
                        <label className='label5-ques'>Number of Offers</label><p></p>
                        <input 
                            type="number" 
                            className='input-ques' 
                            value={noOfOffers} 
                            onChange={(e) => {
                                console.log('Number of Offers input changed:', e.target.value);
                                setNoOfOffers(e.target.value);
                            }} 
                        />
                    </Col>
                </Row><p></p>
                <button 
                    className='button-ques-save' 
                    type="button" // Change to "button" to prevent form submission
                    onClick={handleDownload}
                >
                    Download
                </button>
            </form>
        </div>
    );
};

export default FilterCandidatesDownload;
